import React, { useState } from 'react';
import './Accordion.css'; // Import your custom CSS file
import { Parser } from 'html-to-react'
import DOMPurify from "dompurify";
const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {

    const active = index === activeIndex ? 'active' : '';
const question=DOMPurify.sanitize(item.question)
const anser=DOMPurify.sanitize(item.answer)
    return (
      <div key={item.title} >
        
        <div className={`title ${active}` } style={{paddingTop:"12px",fontWeight:"bold",color:"rgba(255, 255, 255, 0.6)",fontSize:"18px", textAlign:"center"}} onClick={() => onTitleClick(index)}  >
       {question}
        </div>
        
        <div style={{paddingTop:"12px",fontWeight:"bold",fontSize:"18px",color:"rgba(255, 255, 255, 0.6)" ,textAlign:"center"}} className={`content ${active}`}    >
       {anser}
        </div>
       
      </div>
    );
  });

  return <div className="accordion" >{renderedItems}</div>;
};

export default Accordion;

import React from 'react'
import { useState, useEffect } from 'react';
function Term() {
    const [data, setData] = useState()
    useEffect(() => {
        fetch('https://api1.xtable.tv/api/Client/Utilities/GetPolicyData')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data.terms_conditions);
                setData(data.terms_conditions)
            });

    })
    return (

        <section className="py-10 pb-5 mt-5">
            <div className="container-xxxl">
                <div className="col-md-10 mx-auto px-0">
                    <h2 className="mt-0 mb-3 fw-bold text-center" style={{ color: "white" }}>Terms and Conditions:</h2>
                    <ol style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "1.25em" }}>
                        <p className=' pb-5' style={{ WebkitUserModify: "read-write-plaintext-only", cursor: "none", textAlign: "justify", pointerEvents: "none" }} dangerouslySetInnerHTML={{ __html: data }}></p>
                    </ol>
                </div>
            </div>
        </section>

    )
}

export default Term
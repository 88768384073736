import React, { useState, useEffect } from 'react'
import logo from './assets/images/logo.svg';
import google from './assets/images/google-play.png'
import appstore from './assets/images/app-store.png'
import dummy from './assets/images/dummy1.png'
import dummy2 from './assets/images/dummy2.png'
import dummy3 from './assets/images/dummy3.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap.min.css'
import './assets/css/custom.css'
import Accordion from './assets/Accordion';
import { Link } from 'react-router-dom';



function Home() {
    const [mydata, setdata] = useState([])
    const [aboutus, setAbout] = useState()
    useEffect(() => {
        try {
            fetch('https://api1.xtable.tv/api/Client/Utilities/GetPolicyData')
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    console.log(data?.faqs);
                    setdata(data?.faqs)
                });

            fetch('https://api1.xtable.tv/api/Client/Utilities/GetPolicyData')
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    console.log(data?.about_us, "About");
                    setAbout(data?.about_us)
                });

        } catch (error) {
            console.log(error)

        }






    }, [])

    return (
        <div>

            <section className="banner banner-right" id="mybody" >

                <div className="container-xxxl">
                    <div className="row align-items-start">

                        <div className="col-md-6 text-md-start text-center">
                            <h1 className="fw-bold lh-1 mb-0">Rummy With Friends,Reimagined</h1>

                            <ul className="list">
                                <li>Cast the table tv</li>
                                <li>Voice chat</li>
                                <li>Scoreboard history</li>
                                <li>Deal or Pool variation</li>
                                <li>Open / Closed Joker</li>

                            </ul>

                            <button
                                className="btn btn-primary btn-lg text-uppercase"
                                onClick={() => window.location.href = "https://xtable.tv/"}
                            >
                                CONNECT
                            </button>

                        </div>

                    </div>
                </div>

            </section>
            <div className="apps-logos d-flex gap-xxl-4 gap-3 align-items-center mt-4 " style={{ justifyContent: "center", marginTop: "500px" }}>
            <a href="https://play.google.com/store/apps/details?id=com.quytech.xtable" target="_blank" rel="noopener noreferrer">
  <img src={google} alt="Google Play Store" className="apps-logos__img" />
</a>

<a href="https://apps.apple.com/in/app/xtable-tv/id6477372454" target="_blank" rel="noopener noreferrer">
  <img src={appstore} alt="Apple App Store" className="apps-logos__img" />
</a>
            </div>

            <section className="py-10" id="faq">
                <div className="container-xxxl">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="text-center">
                                <h2 className="fw-bold text-white text-uppercase mb-0">FAQ</h2>
                            </div>

                            <div className="mt-4">
                                <Accordion items={mydata} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 " id="about-us" style={{ backgroundColor: "rgba(255, 255, 255, 0.24)" }}>

                <div className="container">
                    <div className="text-center col-md-10 mx-auto px-0">
                        <h2 className="mt-0 fw-bold">About Us</h2>
                        <p style={{ WebkitUserModify: "read-write-plaintext-only", cursor: "none", textAlign: "justify", pointerEvents: "none" }} dangerouslySetInnerHTML={{ __html: aboutus }}></p>

                        {/* <p className="fs-25 mt-4 mb-0" style={{ fontSize: "30px" }}>Xtable is built by the team at MatterX Labs.</p>
                        <p className="fs-25 mt-4 mb-0" style={{ fontSize: "30px" }}>We think differently. </p>
                        <Link className="fs-25 mt-4 mb-0" style={{ fontSize: "30px", color: "rgba(255, 255, 255, 0.6)" }} >contact@matterxlabs.com</Link> */}
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Home
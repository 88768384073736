import React, { useState, useEffect } from 'react'

function Privacy() {
    const [data, setData] = useState()
    const [loading,setLoading]=useState(true)
    useEffect(() => {
        fetch('https://api1.xtable.tv/api/Client/Utilities/GetPolicyData')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data.privacy_policy);
                setData(data.privacy_policy)
                setLoading(false)
            });

    })
    return (

        <section className="py-10 pb-5 mt-5">
            <div className="container-xxxl">
                <div className="col-md-10 mx-auto px-0">
                    <h2 className="mt-0 mb-3 fw-bold text-center" style={{ color: "white" }}>Privacy Policy</h2>
                    <ol style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "1.25em" }}>
                        {/* <p style={{ WebkitUserModify: "read-write-plaintext-only",color:"white" ,cursor: "none", textAlign: "justify", pointerEvents: "none" }} dangerouslySetInnerHTML={{ __html: data }}></p> */}
                        <div className=' pb-5 text-white'dangerouslySetInnerHTML={{ __html: data }}></div>
                    </ol>

                </div>
            </div>
        </section>


    )
}

export default Privacy
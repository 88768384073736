import React from 'react'

import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="text-center">
    <div className="py-3 py-md-4 footer-links">
        <div className="inline-items">

            <Link to="/" className="link">Home</Link>
            <a href="/#about-us" className="link fs-17">About Us</a>
            <a href="/#faq" className="link fs-17">FAQs</a>
            <Link to="/Privacy" className="link">Privacy Policy</Link>
            <Link to="/Term" className="link">Terms & Conditions</Link>

        </div>
    </div>
    <div className="copyright py-4"> &copy; Copyright Xtable Pvt. Ltd. </div>
</footer>
  )
}

import React from 'react'
import logo from '../assets/images/logo.svg';
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-transparent fixed-top" aria-label="Offcanvas navbar large">
    <div className="container-xxxl">
        <a className="navbar-brand" >
            <img src={logo} alt="XTable" />
        </a>

        <div className="offcanvas offcanvas-end text-bg-dark" data-bs-scroll="true" data-bs-backdrop="false"
            tabindex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
            <div className="offcanvas-header">
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 gap-4">
                    <li className="nav-item">

                 
                    <Link to="/" className="nav-link fs-17">Home</Link>
                    </li>
                    <li className="nav-item">
                        <a href="/#about-us" className="nav-link fs-17">About Us</a>
                    </li>
                    <li className="nav-item">

                    <a href="/#faq" className="nav-link fs-17">FAQs</a>
                    </li>
                    <li className="nav-item">

                        <Link to="/Privacy" className="nav-link fs-17">Privacy Policy</Link>
                    </li>
                    <li className="nav-item">

                        <Link to="/Term" className="nav-link fs-17">Terms & Conditions</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
  )
}

export default Header
import Home from './Home';
import Privacy from './Privacy';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import { BrowserRouter, Routes, Route, Link,Redirect } from 'react-router-dom'
// import { BrowserRouter as Routes, Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Term from './Term';
import Header from './Components/Header';
import { Footer } from './Components/Footer';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/Privacy' element={<Privacy />}></Route>
        <Route path='/Term' element={<Term />}></Route>
        {/* Add more routes here if needed */}
      </Routes>
      <Footer />
    </Router>
    // <BrowserRouter basename='/xtable'>
    //   <Header />
    //   <Routes>
    //     <Route path='/' element={<Home />}>
    //     <Redirect to="/xtable" />
    //     </Route>
    //     <Route path='/Home' element={<Home />}></Route>
    //     <Route path='/Privacy' element={<Privacy />}></Route>
    //     <Route path='/Term' element={<Term />}></Route>
    //   </Routes>
    //   <Footer />
    // </BrowserRouter>
  );
}

export default App;
